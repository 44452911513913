module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/fabmob/mob/website/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Accueil","crumbLabelUpdates":[{"pathname":"/decouvrir-le-projet","crumbLabel":"Découvrir le projet"},{"pathname":"/contact","crumbLabel":"Nous contacter"},{"pathname":"/faq","crumbLabel":"Foire aux questions"},{"pathname":"/aide-page","crumbLabel":"Fiche d'aide"},{"pathname":"/recherche","crumbLabel":"Trouver une aide"},{"pathname":"/mentions-legales-cgu","crumbLabel":"Mentions légales et Conditions Générales d’utilisation"},{"pathname":"/politique-gestion-cookies","crumbLabel":"Politique et gestion des cookies"},{"pathname":"/charte-protection-donnees-personnelles","crumbLabel":"Charte de Protection des Données Personnelles"},{"pathname":"/mon-profil","crumbLabel":"Mon profil"},{"pathname":"/gerer-salaries","crumbLabel":"Gérer mes salariés"},{"pathname":"/gerer-citoyens","crumbLabel":"Gérer mes citoyens"},{"pathname":"/administrer-demandes","crumbLabel":"Administrer les demandes"},{"pathname":"/mon-dashboard","crumbLabel":"Mon tableau de bord"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"sm":"(min-width: 576px)","md":"(min-width: 768px)","l":"(min-width: 1024px)","xl":"(min-width: 1440px)","portrait":"(orientation: portrait)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
